import React from "react"
import ThirdPartyStatusBase from "./ThirdPartyStatusBase"
import { doGet } from "@nadinewest/common/javascript/src"

const UPS_STATUS_OK = "OK"

export default class UPSStatus extends ThirdPartyStatusBase {
  static defaultProps = {
    statusTitle: "UPS",
    statusDetailsUrl: "https://www.shippingapimonitor.com/history.html?api=ups"
  }

  constructor(props) {
    super(props)
    this.state = {
      status: null
    }
  }

  updateStatus() {
    this.setState({
      status: "Loading..."
    })
    doGet("/api_status/ups", {}, (response) => {
      this.setState({
        status: response.ok ? UPS_STATUS_OK : "FAILED",
        statusDate: new Date()
      })
    })
  }

  renderStatus() {
    return (
      <h3>
        API:&nbsp;
        <span
          className={this.state.status === UPS_STATUS_OK ? "text-success" : "text-danger"}
        >
          {this.state.status}
        </span>
      </h3>
    )
  }
}
