import React from "react"
import ThirdPartyStatusBase from "./ThirdPartyStatusBase"

export default class AvalaraStatus extends ThirdPartyStatusBase {

  static defaultProps = {
    statusTitle: "Avalara",
    statusDetailsUrl: "https://status.avalara.com"
  }

  constructor(props) {
    super(props)
    this.state = {
      statusIndicator: null,
      statusDescription: null
    }
  }

  updateStatus() {
    this.setState({
      statusIndicator: "loading",
      statusDescription: "Loading..."
    })
    fetch("https://status.avalara.com/api/v2/status.json").
      then(response => {
        response.ok ?
          response.json().then(result => {
            this.setState({
              statusIndicator: result.status.indicator,
              statusDescription: result.status.description,
              statusDate: new Date()
            })
          }) :
          this.setState({
            statusIndicator: "error",
            statusDescription: `FAIL: ${response.status}`,
            statusDate: new Date()
          })
      }).
      catch(error => {
        console.error("Unexpected error while trying to check Avalara API status: ", error.message)
        this.setState({
          statusIndicator: "error",
          statusDescription: "Unexpected Error",
          statusDate: new Date()
        })
      })
  }

  renderStatus() {
    return (
      <h3>
        Status:&nbsp;
        <span
          className={this.state.statusIndicator == "none" ? "text-success" : "text-danger"}
        >
          {this.state.statusDescription}
        </span>
      </h3>
    )
  }
}