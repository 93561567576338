import React from "react"
import { Col, Row } from "react-bootstrap"
import HerokuStatus from "./HerokuStatus"
import PitneyBowesStatus from "./PitneyBowesStatus"
import PrintNodeStatus from "./PrintNodeStatus"
import AvalaraStatus from "./AvalaraStatus"
import UPSStatus from "./UPSStatus"

export default class ThirdPartyStatus extends React.PureComponent {

  render() {
    return (
      <Row>
        <Col sm={6}>
          <PrintNodeStatus />
          <UPSStatus />
        </Col>
        <Col sm={6}>
          <AvalaraStatus />
          <HerokuStatus />
        </Col>
        <Col sm={6}>
        </Col>
      </Row>
    )
  }
}
